.btn{
	position: relative;
	background: none;
	border: none;
	padding: 0;
	width: 50%;
	max-width: 20rem;
	font-size: 4vw;
	.inner{
		background-color: #eab300;
		background-image: linear-gradient(
						hsla(0, 0%, 100%, 0.6),
						hsla(0, 0%, 20%, 0) 50%,
						hsla(0, 0%, 50%, 0.2) 50%,
						hsla(0, 0%, 100%, 0.2)
		);
		border: none;
		cursor: pointer;
		border-radius: 2rem;
		padding: 0.5rem 2rem;
		max-width: 100%;
		transform: perspective(10rem) rotateY(-10deg) rotateX(10deg);
		z-index: 10;
		position: relative;
		transition: all 0.35s;
		&:before{
			content: '';
			display: block;
			position: absolute;
			left: 5%;
			right: 20px;
			top: 5%;
			height: 45%;
			border-radius: 2rem;
			background: linear-gradient(hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0) );
		}
	}
	&:after{
		transition: all 0.35s;
		content: "";
		display: block;
		position: absolute;
		left: 2%;
		right: 0;
		top: -0.5rem;
		height: 120%;
		border-radius: 2rem;
		background: linear-gradient(rgba(0,0,0,0.05) 50%, rgba(0,0,0,0.1));
		z-index: -1;
		transform: perspective(42rem) rotateY(-11deg) rotateX(28deg);
	}
	&:hover{
		.inner{
			transform: rotateY(0) rotateX(0);
			box-shadow: 0 3rem 4rem -1rem rgba(0,0,0,0.25);
		}
		&:after{
			top: 0;
			height: 100%;
			opacity: 0;
			transform: rotateY(0) rotateX(0);
		}
	}
}
