.landscape{
	display: flex;
	flex: 1;
	position: absolute;
	height: 100%;
	width: 100%;
	flex-direction: column;
	overflow: hidden;
	.mountains{
		background-image: url("../../img/mountains.svg");
		background-repeat: no-repeat;
		background-position: top;
		position: absolute;
		bottom: 22vw;
		left: -20vw;
		right: -20vw;
		height: 35vw;
		background-size: 100%;
	}
	.background{
		background-image: url("../../img/landscape.svg");
		background-repeat: no-repeat;
		background-position: bottom;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 50%;
		background-size: 100%;
	}
	@import "clouds";
}
