@import "font";
@import "defaults";
*{
	box-sizing: border-box;
	font-family: "Chewy", sans-serif;
	color: white;
	font-size: 100%;
}
html, body{
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}
body{
	display: flex;
	flex: 1;
	flex-direction: column;
	background: linear-gradient(to bottom, #90dffe 0%,#38a3d1 100%);
	#app{
		display: flex;
		flex: 1;
		flex-direction: column;
		@import "landscape/landscape";
		.start{
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			flex-direction: column;
			position: relative;
			z-index: 100;
			> div{
				display: flex;
				align-items: center;
				flex-direction: column;
			}
			h1{
				font-size: 16vw;
				text-shadow: 0 2rem 2rem rgb(0 0 0 / 25%);
				display: flex;
				margin: 0;
				line-height: 10vw;
			}
			h2{
				margin: 0 0 6rem;
				font-size: 10vw;
				color: orange;
				text-shadow: 0 2rem 2rem rgb(0 0 0 / 25%);
			}
		}
	}
}
